import { create } from 'zustand';
const useToastStore = create(set => {
    return {
        toastConfig: {
            speed: 3000,
            position: 'top',
        },
        toast: {
            visible: false,
            content: null,
            type: 'success',
        },
        setToast: toast => {
            set(current => ({
                toast: Object.assign(Object.assign({}, current.toast), toast),
            }));
        },
        setToastConfig: config => {
            set(current => ({ toastConfig: Object.assign(Object.assign({}, current.toastConfig), config) }));
        },
        success: toast => {
            set({
                toast: Object.assign(Object.assign({}, toast), { visible: true, type: 'success' }),
            });
        },
    };
});
export default useToastStore;
