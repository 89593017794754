import I18n from 'i18n-js';
/** @TODO: Copy from: https://gitlab.myteksi.net/engineering/web-kit/merge_requests/13148/diffs#diff-content-ceb8199cf90d6c15baf26acfbc950ba542b5b7e6
 * Will remove after MR: 13148 is merged
 * */
export const translateHandler = (localePrefix = '') => (localeKey, localeParams = {}) => {
    const commonKeyRegex = /^common.*/;
    const isCommon = commonKeyRegex.test(localeKey);
    if (isCommon || !localePrefix) {
        return I18n.t(`${localeKey}`, Object.assign({}, localeParams));
    }
    return I18n.t(`${localePrefix}.${localeKey}`, Object.assign({}, localeParams));
};
