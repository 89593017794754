import { GST_PERCENTAGE, GST_PERCENTAGE_AFTER_CHANGE, } from '@constants/constants';
import { calculateBaseRateWithTax, formatCurrency } from './rates';
let gstRate = GST_PERCENTAGE;
export const getGstRate = () => {
    return gstRate;
};
export const setGstRateByDate = () => {
    const now = new Date();
    const january2024 = new Date(2024, 0);
    const isAfterJanuary2024 = now >= january2024;
    if (isAfterJanuary2024) {
        gstRate = GST_PERCENTAGE_AFTER_CHANGE;
    }
};
export const parseCurrency = (amount) => {
    // Remove the currency symbols and any alphabets or spaces (e.g., 'S$', 'Rp', '$', etc.)
    let numberString = amount.replace(/[^0-9.,]/g, '');
    let hasThousandsSeparator = (numberString.match(/,/g) || []).length === 1;
    let hasDecimalSeparator = (numberString.match(/\./g) || []).length === 1;
    // Establish the thousand and decimal separators based on the presence in the string
    let thousandSeparator = hasThousandsSeparator ? ',' : null;
    let decimalSeparator = hasDecimalSeparator ? '.' : null;
    // If both separators found, determine their position to decide which is which.
    if (hasThousandsSeparator && hasDecimalSeparator) {
        thousandSeparator =
            numberString.indexOf(',') < numberString.indexOf('.') ? ',' : null;
        decimalSeparator =
            numberString.indexOf('.') > numberString.indexOf(',') ? '.' : null;
    }
    // Remove thousand separator if identified
    if (thousandSeparator) {
        numberString = numberString.replace(new RegExp('\\' + thousandSeparator, 'g'), '');
    }
    // Replace the decimal separator with a dot if it's a comma
    if (decimalSeparator === ',') {
        numberString = numberString.replace(/,/g, '.');
    }
    const numberValue = parseFloat(numberString);
    return numberValue;
};
export const getRateWithGstFromStringRate = (price) => formatCurrency(calculateBaseRateWithTax(getGstRate(), parseCurrency(price)), 'SGD', 'en-SG');
