import React from 'react';
import { Grid } from 'antd-mobile';
import { convertTimeSlotObjectToString } from '@helpers';
import styles from './ListDescriptionCandidate.less';
import useTranslate from '@hooks/useTranslate';
const ListDescriptionCandidate = ({ candidate, }) => {
    var _a, _b;
    const { zTranslate } = useTranslate();
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, candidate.preference.parking_address),
        React.createElement(Grid, { columns: 1 },
            React.createElement(Grid.Item, { span: 1 },
                React.createElement("span", null, zTranslate('dax_vehicle_sharing_label_prefers_to_drive'))),
            React.createElement(Grid.Item, { span: 1 },
                React.createElement("span", { className: styles.timeSlot }, convertTimeSlotObjectToString((_b = (_a = candidate === null || candidate === void 0 ? void 0 : candidate.preference) === null || _a === void 0 ? void 0 : _a.driving_schedule) === null || _b === void 0 ? void 0 : _b.time_slot))))));
};
export default ListDescriptionCandidate;
