import { round } from 'lodash';
export const calculateGstRate = (applicableGstRate = 0) => 1 + applicableGstRate / 100;
export const calculateBaseRateWithTax = (applicableGstRate = 0, amountWithoutGST = 0) => {
    const gstAmountHundred = applicableGstRate * amountWithoutGST;
    const gstAmount = round(gstAmountHundred / 100, 2);
    return round(amountWithoutGST + gstAmount, 2);
};
export function formatCurrency(amount, currency, locale) {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });
    let formattedAmount = formatter.format(amount);
    if (currency === 'SGD') {
        formattedAmount = formattedAmount.replace('$', 'S$');
    }
    return formattedAmount;
}
export function currencyToNumber(currencyString) {
    // Remove any non-numeric characters except for the last period or comma (for decimal separator)
    if (!currencyString)
        return 0;
    const cleanedString = currencyString
        .replace(/[^0-9,.]/g, '')
        .replace(/,(?=[^,]*$)/, '.');
    // Parse the cleaned string to a number
    const numberValue = parseFloat(cleanedString);
    if (isNaN(numberValue))
        return 0;
    return numberValue;
}
