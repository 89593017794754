import React from 'react';
import { Popover, Button } from 'antd-mobile';
import styles from './PopoverGuide.less';
const PopoverGuide = ({ children, visible, onClick, title, subtitle, textButton, }) => {
    return (React.createElement(Popover, { visible: visible, content: React.createElement("div", { className: styles.popoverContainer },
            React.createElement("p", { className: styles.title }, title),
            React.createElement("div", null, subtitle),
            React.createElement(Button, { type: "submit", color: "success", size: "small", className: styles.button, onClick: onClick }, textButton)) }, children));
};
export default PopoverGuide;
