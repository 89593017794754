// @flow
import { ENVIRONMENT } from '@constants/constants';
export const buildResponse = (status, data, error) => ({
    status,
    data,
    error,
});
export const getIsFirstVisitStateFromLocalStorage = () => {
    const item = localStorage.getItem('isFirstVisit');
    return item ? JSON.parse(item) : null;
};
export const createIsFirstVisitState = () => {
    if (getIsFirstVisitStateFromLocalStorage()) {
        return;
    }
    const isFirstVisit = {
        interestedPage: true,
        searchDriversResultPage: true,
        proposalInvitationPage: true,
    };
    localStorage.setItem('isFirstVisit', JSON.stringify(isFirstVisit));
};
export function formatPhoneNumber(phoneNumberStr) {
    if (!phoneNumberStr)
        return null;
    let formattedNumber = '(+' +
        phoneNumberStr.slice(0, 2) +
        ') ' +
        phoneNumberStr.slice(2, 6) +
        '-' +
        phoneNumberStr.slice(6, 10);
    return formattedNumber;
}
export const getEnvironment = () => {
    if (window.location.hostname.includes('.grab.com')) {
        return ENVIRONMENT.PRD;
    }
    else if (window.location.hostname.includes('.stg-myteksi.com')) {
        return ENVIRONMENT.STG;
    }
    return ENVIRONMENT.DEV;
};
export const getPublicS3Url = () => {
    if (window.location.hostname.includes('.grab.com')) {
        return 'https://daxexp.grab.com';
    }
    else if (window.location.hostname.includes('.stg-myteksi.com')) {
        return 'https://daxexp.stg-myteksi.com';
    }
    return 'https://daxexp.stg-myteksi.com';
};
export const removeCountryCode = (phoneNumber, countryCode = '65') => {
    if (!phoneNumber)
        return phoneNumber;
    if (phoneNumber.startsWith(countryCode)) {
        return phoneNumber.slice(countryCode.length);
    }
    return phoneNumber;
};
export const replaceErrors = (_, value) => {
    if (value instanceof Error) {
        let error = {};
        Object.getOwnPropertyNames(value).forEach(propName => {
            error[propName] = value[propName];
        });
        return error;
    }
    return value;
};
