import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from './loader.json';
import styles from './SpinnerBottom.less';
const SpinnerBottom = () => {
    const animationContainer = useRef(null);
    useEffect(() => {
        if (animationContainer.current) {
            const animation = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
            });
            return () => {
                animation.destroy();
            };
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.loading_animation_container, ref: animationContainer })));
};
export default SpinnerBottom;
