import { GetterNativeEvents } from './constants';
import { getMessageSender } from './messageSender';
import { includes } from 'lodash';
import { IS_IOS } from './utils';
export class JSBridge {
    constructor() {
        this._promises = {};
        // To be used for asserting cleanup
        this._getPromises = () => this._promises;
        window.webMsgHandler = (message) => {
            if (!message) {
                return;
            }
            const data = 'data' in message ? message.data : message;
            if (includes(GetterNativeEvents, data.type) &&
                this._promises[data.type]) {
                const executePromise = ({ resolve, reject }) => data.error ? reject(data.error) : resolve(data.value);
                this._promises[data.type].forEach(executePromise);
                delete this._promises[data.type];
            }
        };
        if (IS_IOS()) {
            window.addEventListener('message', (event) => window.webMsgHandler(event));
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    send(type, value) {
        const messageSender = getMessageSender();
        if (!messageSender) {
            // eslint-disable-next-line no-console
            console.warn(`This env doesn't receive native message. Received:`, {
                type,
                value,
            });
            return Promise.resolve();
        }
        messageSender.send({
            type,
            value,
        });
        return new Promise((resolve, reject) => {
            if (!includes(GetterNativeEvents, type)) {
                resolve(true);
                return;
            }
            this._promises[type] = this._promises[type] || [];
            this._promises[type].push({ resolve, reject });
        });
    }
}
