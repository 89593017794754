import * as React from 'react';
import { Form, Grid, Radio } from 'antd-mobile';
import styles from './TimeSlot.less';
import useTranslate from '@hooks/useTranslate';
const TimeSlot = ({ className, onRadioButtonClick, }) => {
    const { zTranslate } = useTranslate();
    const availableTimeSlots = [
        {
            title: '4:00 AM - 3:59 PM',
            subtitle: zTranslate('dax_vehicle_sharing_body_daily_rental_rate', {
                rate: 'S$36',
            }),
        },
        {
            title: '4:00 PM - 3:59 AM',
            subtitle: zTranslate('dax_vehicle_sharing_body_daily_rental_rate', {
                rate: 'S$30',
            }),
        },
        {
            title: '11:00 AM - 10:59 PM',
            subtitle: zTranslate('dax_vehicle_sharing_body_daily_rental_rate', {
                rate: 'S$37.50',
            }),
        },
        {
            title: '11:00 PM - 10:59 AM',
            subtitle: zTranslate('dax_vehicle_sharing_body_daily_rental_rate', {
                rate: 'S$28.50',
            }),
        },
    ];
    return (React.createElement(Form.Item, { shouldUpdate: true, className: className, name: "timeSlot", rules: [{ required: true }] },
        React.createElement(Radio.Group, null, availableTimeSlots.map((availableTimeSlot, index) => (React.createElement("div", { key: availableTimeSlot.title, onClick: e => onRadioButtonClick(e, availableTimeSlot.title) },
            React.createElement(Grid, { columns: 12 },
                React.createElement(Grid.Item, { span: 11 },
                    React.createElement(Grid, { columns: 1 },
                        React.createElement(Grid.Item, { span: 1 },
                            React.createElement("span", null, availableTimeSlot.title)),
                        React.createElement(Grid.Item, { span: 1 },
                            React.createElement("span", { className: styles.subtitle }, availableTimeSlot.subtitle)))),
                React.createElement(Grid.Item, { span: 1 },
                    React.createElement(Radio, { value: availableTimeSlot.title, onClick: (e) => onRadioButtonClick(e, availableTimeSlot.title), className: styles.radio, icon: (checked) => {
                            if (checked) {
                                return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
                                    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0ZM10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10C2 14.42 5.58 18 10 18ZM10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15Z", fill: "#00B14F" })));
                            }
                            return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20", fill: "none" },
                                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z", fill: "#8C8C8C" })));
                        } }))),
            index !== availableTimeSlots.length - 1 ? (React.createElement("hr", { className: styles.line })) : null))))));
};
export default TimeSlot;
