var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import Spin from 'grab-ui/dist/Spin';
import styles from './Spinner.less';
const Spinner = (_a) => {
    var { withWrapper = true, size } = _a, rest = __rest(_a, ["withWrapper", "size"]);
    if (!withWrapper) {
        return React.createElement(Spin, Object.assign({ size: size }, rest));
    }
    return (React.createElement("div", Object.assign({ className: styles.wrapper }, rest),
        React.createElement(Spin, { size: size })));
};
export default Spinner;
