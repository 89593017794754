import React, { useEffect } from 'react';
import clsx from 'clsx';
import useToastStore from '@stores/useToastStore';
import styles from './Toasty.less';
const Toasty = () => {
    const toast = useToastStore(state => state.toast);
    const toastConfig = useToastStore(state => state.toastConfig);
    const setToast = useToastStore(state => state.setToast);
    useEffect(() => {
        let timeout;
        if (toast.visible && !toast.persistent) {
            timeout = setTimeout(() => {
                setToast({ visible: false, type: 'success' });
            }, toastConfig.speed);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [toast.visible]);
    const handleCloseToast = () => {
        setToast({ visible: false });
    };
    return (React.createElement("div", { className: clsx(styles.toastContainer, toast.visible && styles.visible, styles.success) },
        React.createElement("div", { className: styles.toastContent },
            React.createElement("div", { className: styles.toastIcon },
                React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM14.7071 8.7071C15.0976 8.3166 15.0976 7.68342 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L9 11.5858L6.70711 9.2929C6.31658 8.9024 5.68342 8.9024 5.29289 9.2929C4.90237 9.6834 4.90237 10.3166 5.29289 10.7071L8.2929 13.7071C8.6834 14.0976 9.3166 14.0976 9.7071 13.7071L14.7071 8.7071Z", fill: "white" }))),
            React.createElement("p", { className: styles.toastMessage }, toast.content)),
        React.createElement("button", { className: styles.toastClose, onClick: handleCloseToast },
            React.createElement("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M13.4226 1.75658C13.748 1.43113 13.748 0.903501 13.4226 0.578059C13.0972 0.252626 12.5695 0.252626 12.2441 0.578059L7.00001 5.82215L1.75593 0.578059C1.4305 0.252626 0.902869 0.252626 0.577427 0.578059C0.251994 0.903501 0.251994 1.43113 0.577427 1.75658L5.82151 7.00065L0.577419 12.2447C0.251985 12.5702 0.251985 13.0978 0.577419 13.4232C0.90286 13.7487 1.43049 13.7487 1.75593 13.4232L7.00001 8.17915L12.2441 13.4232C12.5695 13.7487 13.0972 13.7487 13.4226 13.4232C13.748 13.0978 13.748 12.5702 13.4226 12.2447L8.17851 7.00065L13.4226 1.75658Z", fill: "white" })))));
};
export default Toasty;
