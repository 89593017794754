import React from 'react';
import { Switch } from 'antd-mobile';
import { useVehicleProviderListingStore } from '@stores/car-sharing-v2/useVehicleProviderListingStore';
import styles from './StyledList.less';
const ShowListingSwitch = () => {
    const { listing, toggleListingVisibilityLoading, toggleListingVisibility, } = useVehicleProviderListingStore();
    return (React.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        } },
        React.createElement("p", null, "Show listing"),
        React.createElement(Switch, { style: { '--height': '20px', '--width': '36px' }, onChange: () => toggleListingVisibility(), loading: toggleListingVisibilityLoading, checked: listing === null || listing === void 0 ? void 0 : listing.searchable })));
};
const StyledList = ({ data }) => {
    return (React.createElement("div", { className: styles.listingDetail }, data.map((detail, index) => (React.createElement("div", { key: index, className: styles.listingDetailItem },
        React.createElement("h4", { className: styles.title }, detail.title),
        React.createElement("div", { className: styles.contentContainer },
            detail.component && detail.component,
            detail.content && (React.createElement("p", { className: styles.content }, detail.content)),
            detail.small && React.createElement("p", { className: styles.small }, detail.small),
            detail.switch && React.createElement(ShowListingSwitch, null),
            index + 1 < data.length && React.createElement("hr", null)))))));
};
export default StyledList;
