import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import { isAuthenticated } from 'grab-login/lib/helper';

function ProtectedRoute({ authRequired, location, ...rest }) {
  if (authRequired) {
    if (!isAuthenticated()) {
      const to = { pathname: '/login' };

      if (location) {
        to.state = { from: location };
      }

      return <Link to={to} />;
    }
  }

  return <Route {...rest} />;
}

ProtectedRoute.defaultProps = {
  authRequired: true,
  location: undefined,
};

ProtectedRoute.propTypes = {
  authRequired: PropTypes.bool,
  location: PropTypes.shape({}),
};

export default ProtectedRoute;
