var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { get as AxiosGet, post as AxiosPost } from '@helpers';
export const useVehicleProviderListingStore = create()(set => ({
    listing: undefined,
    userInfo: undefined,
    dataLoading: false,
    responseStatusCode: undefined,
    error: false,
    fetch: () => __awaiter(void 0, void 0, void 0, function* () {
        set({ dataLoading: true });
        const listingResponse = yield AxiosGet(`${window.Grab.config.uri}/rentals/vehicle-sharing/v2/listing`);
        const userInfoResponse = yield AxiosGet(`${window.Grab.config.uri}/rentals/vehicle-sharing/v2/user-info`);
        if (listingResponse.status !== 200 || userInfoResponse.status !== 200) {
            set({
                dataLoading: false,
                responseStatusCode: listingResponse.status,
                error: true,
                listing: undefined,
                userInfo: undefined,
            });
            return [];
        }
        const fetchedListing = listingResponse.data.listing;
        const fetchedUserInfo = userInfoResponse.data.user_info;
        set({
            dataLoading: false,
            listing: fetchedListing,
            userInfo: fetchedUserInfo,
            responseStatusCode: listingResponse.status,
        });
        return [fetchedListing, fetchedUserInfo];
    }),
    toggleListingVisibilityLoading: false,
    toggleListingVisibility: () => __awaiter(void 0, void 0, void 0, function* () {
        set({ toggleListingVisibilityLoading: true });
        const toggleListingResp = yield AxiosPost(`${window.Grab.config.uri}/rentals/vehicle-sharing/v2/toggle-listing`);
        if (toggleListingResp.status !== 200 || !toggleListingResp.data.success) {
            return;
        }
        set(state => ({
            toggleListingVisibilityLoading: false,
            listing: state.listing && Object.assign(Object.assign({}, state.listing), { searchable: !state.listing.searchable }),
        }));
    }),
}));
