import React from 'react';
import styles from './CircleBox.less';
import { Grid } from 'antd-mobile';
import { convertToKm } from '@helpers';
const CircleBox = ({ distance, imgSrc, alias, withDistance = true, }) => {
    const distanceString = convertToKm(distance);
    const firstLetterOfAlias = alias ? alias[0].toUpperCase() : '';
    return (React.createElement("div", { className: styles.circleBox },
        React.createElement("div", null,
            React.createElement(Grid, { columns: 1 },
                React.createElement(Grid.Item, { span: 1 }, imgSrc ? (React.createElement("img", { src: imgSrc, alt: "", className: styles.circleBoxImage })) : (React.createElement("div", { className: styles.alias },
                    " ",
                    firstLetterOfAlias,
                    " "))),
                withDistance && (React.createElement(Grid.Item, { span: 1 },
                    React.createElement("p", { className: styles.circleBoxDistance }, distanceString)))))));
};
export default CircleBox;
