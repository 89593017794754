var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import clsx from 'clsx';
import styles from './Button.less';
const Button = (_a) => {
    var { children, block = false, color = 'primary', // Set a default value for the 'color' prop
    size = 'md', // Set a default value for the 'size' prop
    onClick } = _a, rest = __rest(_a, ["children", "block", "color", "size", "onClick"]);
    return (React.createElement("button", Object.assign({}, rest, { type: "button", className: clsx(styles.button, styles[color], block && styles.block, styles[size]), onClick: onClick }), children));
};
export default Button;
