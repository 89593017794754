import React, { useRef, useEffect, useState } from 'react';
import styles from './Slider.less';
const Slider = ({ icon, labelText, onSuccess, iconDisabled, }) => {
    const slider = useRef(null);
    const [isDragFinished, setDragFinished] = useState(false);
    useEffect(() => {
        const sliderEle = slider.current;
        let currentPos = 0;
        let maxPos = 0;
        let startMoving = false;
        let dragging = false;
        const threshold = 0.98;
        const handleDown = (e) => {
            if (isDragFinished) {
                return;
            }
            e.preventDefault();
            startMoving = true;
            currentPos = e.type === 'mousedown' ? e.clientX : e.touches[0].clientX;
        };
        const handleMove = (e) => {
            if (!startMoving || !sliderEle || isDragFinished)
                return;
            dragging = true;
            const movePos = e.type === 'mousemove' ? e.clientX : e.touches[0].clientX;
            let newPos = movePos - currentPos;
            newPos = Math.max(Math.min(newPos, maxPos), 0);
            sliderEle.style.transform = `translateX(${newPos}px)`;
        };
        const handleUp = (e) => {
            e.preventDefault();
            startMoving = false;
            if (dragging && !isDragFinished && sliderEle) {
                const movePos = e.type === 'mouseup' ? e.clientX : e.changedTouches[0].clientX;
                if (movePos - currentPos < maxPos * threshold) {
                    sliderEle.style.transform = `translateX(0)`;
                }
                else {
                    setDragFinished(true);
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess().then(() => {
                        // handle success here if needed
                    }).catch(() => {
                        setDragFinished(false);
                        sliderEle.style.transform = `translateX(0)`;
                    });
                }
                dragging = false;
            }
        };
        if (sliderEle) {
            sliderEle.addEventListener('mousedown', handleDown);
            sliderEle.addEventListener('touchstart', handleDown);
            sliderEle.addEventListener('mousemove', handleMove);
            sliderEle.addEventListener('touchmove', handleMove);
            sliderEle.addEventListener('mouseup', handleUp);
            sliderEle.addEventListener('touchend', handleUp);
            maxPos = sliderEle.parentElement.offsetWidth - sliderEle.offsetWidth - 5;
        }
        return () => {
            if (sliderEle) {
                sliderEle.removeEventListener('mousedown', handleDown);
                sliderEle.removeEventListener('touchstart', handleDown);
                sliderEle.removeEventListener('mousemove', handleMove);
                sliderEle.removeEventListener('touchmove', handleMove);
                sliderEle.removeEventListener('mouseup', handleUp);
                sliderEle.removeEventListener('touchend', handleUp);
            }
        };
    }, [isDragFinished]);
    return (React.createElement("div", { className: isDragFinished ? styles.SliderButtonDisabled : styles.SliderButton },
        React.createElement("div", { className: styles.SliderButtonLabel }, labelText),
        React.createElement("div", { className: styles.SliderThumb, ref: slider }, isDragFinished ? iconDisabled : icon)));
};
export default Slider;
