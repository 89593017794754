import * as React from 'react';
// import { useLocation } from 'react-router-dom';
import I18n from 'i18n-js';
import { Dropdown, Icon, Layout, Menu } from 'grab-ui';
import { getCurrentUser, logout } from 'grab-login/lib/helper';
import styles from './AppBar.less';
const { Item } = Menu;
const { Header } = Layout;
export const AppBar = (props) => {
    const currentUser = getCurrentUser();
    const handleClick = (item) => {
        switch (item.key) {
            case 'logout':
                logout();
                // props.history.replace({ pathname: '/login' });
                break;
            default:
            // Do nothing
        }
    };
    return (React.createElement(Header, { className: styles.container },
        React.createElement(Dropdown, { overlay: React.createElement(Menu, { onClick: handleClick },
                React.createElement(Item, { key: "logout", className: styles.menu },
                    React.createElement(Icon, { type: "logout" }),
                    " ",
                    I18n.t('sessions.logout'))) },
            React.createElement("a", { className: styles.profile, href: "#" },
                React.createElement("img", { className: styles.avatar, alt: "avatar", src: require('public/images/avatar.svg') }),
                React.createElement("span", { className: styles.name }, currentUser ? currentUser.name : null),
                React.createElement(Icon, { type: "down" })))));
};
AppBar.displayName = 'AppBar';
export default AppBar;
