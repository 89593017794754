import React from 'react';
import clsx from 'clsx';
import Flex from '@components/flex';
import styles from './ItemList.less';
const { item_label_text, item_value_text, text_primary, text_secondary, text_regular, text_small, } = styles;
const ItemList = ({ itemList, itemLabelProps, itemValueProps, itemOrientation = 'column', listGap = 0, labelValueGap, }) => {
    return (React.createElement(Flex, { direction: "column", gap: listGap, fullWidth: true }, itemList.map((item, index) => (React.createElement(Flex, { fullWidth: true, key: index, direction: itemOrientation, justify: itemOrientation === 'row' && !labelValueGap
            ? 'space-between'
            : 'flex-start', gap: labelValueGap },
        React.createElement("div", { className: clsx((itemLabelProps === null || itemLabelProps === void 0 ? void 0 : itemLabelProps.secondary) ? text_secondary : text_primary, (itemLabelProps === null || itemLabelProps === void 0 ? void 0 : itemLabelProps.small) ? text_small : text_regular) }, typeof item.label === 'string' ? (React.createElement("p", { className: item_label_text }, item.label)) : (item.label)),
        item.value && (React.createElement("div", { className: clsx((itemValueProps === null || itemValueProps === void 0 ? void 0 : itemValueProps.secondary) ? text_secondary : text_primary, (itemValueProps === null || itemValueProps === void 0 ? void 0 : itemValueProps.small) ? text_small : text_regular) }, typeof item.value === 'string' ? (React.createElement("p", { className: item_value_text }, item.value)) : (item.value))))))));
};
export default ItemList;
