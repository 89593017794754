import { IS_IOS, IS_ANDROID } from './utils';
class IOSMessageSender {
    send({ type, value }) {
        window.webkit.messageHandlers.onNativeCalled.postMessage({
            type: type,
            value: value,
        });
    }
}
class AndroidMessageSender {
    send({ type, value }) {
        window.AndroidMsgHandler.onMessage(JSON.stringify({ type, value }));
    }
}
export const getMessageSender = () => {
    if (IS_IOS()) {
        return new IOSMessageSender();
    }
    if (IS_ANDROID()) {
        return new AndroidMessageSender();
    }
    return null;
};
