import { AGREEMENT_STATUSES } from '@constants/constants';
export const getCurrentParticipant = (daxId, participants) => {
    if (!participants || !daxId)
        return undefined;
    return participants.find(participant => participant.id === daxId);
};
export const getPartnerParticipant = (daxId, participants) => {
    if (!participants || !daxId)
        return undefined;
    return participants.find(participant => participant.id !== daxId);
};
// this function usage is to check if the participant already agree and pay deposit or not
// if the participant does not have rental agreement, we only check if they already agree to contract or not
// if the participant does have rental agreement, we check both agree to contract and pay deposit
export const isAgreedAndOrPaidDeposit = (proposalParticipant) => {
    if (!proposalParticipant)
        return false;
    if (proposalParticipant === null || proposalParticipant === void 0 ? void 0 : proposalParticipant.have_active_agreement) {
        return proposalParticipant.agree_contract_preview;
    }
    return ((proposalParticipant === null || proposalParticipant === void 0 ? void 0 : proposalParticipant.agree_contract_preview) && (proposalParticipant === null || proposalParticipant === void 0 ? void 0 : proposalParticipant.deposit_proofs));
};
// to check if one of the participant already agree and pay deposit, but the other one does not
export const isOneOfTheParticipantAgreedAndOrPaidDeposit = (currentParticipant, partnerParticipant) => {
    return ((isAgreedAndOrPaidDeposit(currentParticipant) &&
        !isAgreedAndOrPaidDeposit(partnerParticipant)) ||
        (!isAgreedAndOrPaidDeposit(currentParticipant) &&
            isAgreedAndOrPaidDeposit(partnerParticipant)));
};
export const getProposalPairingScenario = (senderParticipant, receiverParticipant) => {
    // no car scenario, check if both have no agreement or not
    const noCarScenario = !(senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.have_active_agreement) &&
        !(receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.have_active_agreement);
    // no car + open scenario, check if one of the participant have no agreement and the other one have open agreement
    // no matter if the sender is the one with open agreement or the receiver, the outcome will be the same
    const noCarAndOpenScenario = (!(senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.have_active_agreement) &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN) ||
        (!(receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.have_active_agreement) &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN);
    // no car + open swap scenario, check if one of the participant have no agreement and the other one have open contract agreement and is swapping car
    // no matter if the sender is the one with open contract agreement or the receiver, the outcome will be the same
    const noCarAndOpenContractSwapScenario = (!(senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.have_active_agreement) &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        receiverParticipant.is_swapping_car) ||
        (!(receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.have_active_agreement) &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) ===
                AGREEMENT_STATUSES.OPEN_CONTRACT &&
            senderParticipant.is_swapping_car);
    // no car + open no swap scenario, check if one of the participant have no agreement and the other one have open contract agreement and is not swapping car
    // no matter if the sender is the one with open contract agreement or the receiver, the outcome will be the same
    const noCarAndOpenContractNoSwapScenario = (!(senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.have_active_agreement) &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        !receiverParticipant.is_swapping_car) ||
        (!(receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.have_active_agreement) &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) ===
                AGREEMENT_STATUSES.OPEN_CONTRACT &&
            !senderParticipant.is_swapping_car);
    // open + open contract swap car, check if the agreement status is open, and the other one is open contract and is swapping car
    // which one is the sender and which one is the receiver does not matter, the outcome will be the same
    const openAndOpenContractSwapCar = ((senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        receiverParticipant.is_swapping_car) ||
        ((receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) ===
                AGREEMENT_STATUSES.OPEN_CONTRACT &&
            senderParticipant.is_swapping_car);
    // open + open contract no swap car, check if the agreement status is open, and the other one is open contract and is not swapping car
    // which one is the sender and which one is the receiver does not matter, the outcome will be the same
    const openAndOpenContractNoSwapCar = ((senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        !receiverParticipant.is_swapping_car) ||
        ((receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) ===
                AGREEMENT_STATUSES.OPEN_CONTRACT &&
            !senderParticipant.is_swapping_car);
    // open contract swap car + open contract swap car, check if the agreement status is open contract and is swapping car, and the other one is open contract and is swapping car
    // which one is the sender and which one is the receiver does not matter, the outcome will be the same
    const openContractSwapScenario = (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN_CONTRACT &&
        senderParticipant.is_swapping_car &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        receiverParticipant.is_swapping_car;
    // open contract swap car + open contract no swap car, check if the agreement status is open contract and is swapping car, and the other one is open contract and is not swapping car
    // which one is the sender and which one is the receiver does not matter, the outcome will be the same
    const openContractSwapCarAndOpenContractNoSwapCarScenario = ((senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN_CONTRACT &&
        senderParticipant.is_swapping_car &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        !receiverParticipant.is_swapping_car) ||
        ((receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
            receiverParticipant.is_swapping_car &&
            (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) ===
                AGREEMENT_STATUSES.OPEN_CONTRACT &&
            !senderParticipant.is_swapping_car);
    // open contract no swap car + open contract no swap car, check if the agreement status is open contract and is not swapping car, and the other one is open contract and is not swapping car
    // which one is the sender and which one is the receiver does not matter, the outcome will be the same
    const openContractNoSwapCarScenario = (senderParticipant === null || senderParticipant === void 0 ? void 0 : senderParticipant.agreement_status) === AGREEMENT_STATUSES.OPEN_CONTRACT &&
        !senderParticipant.is_swapping_car &&
        (receiverParticipant === null || receiverParticipant === void 0 ? void 0 : receiverParticipant.agreement_status) ===
            AGREEMENT_STATUSES.OPEN_CONTRACT &&
        !receiverParticipant.is_swapping_car;
    const scenario = {
        noCarScenario,
        noCarAndOpenScenario,
        noCarAndOpenContractSwapScenario,
        noCarAndOpenContractNoSwapScenario,
        openAndOpenContractSwapCar,
        openAndOpenContractNoSwapCar,
        openContractSwapScenario,
        openContractSwapCarAndOpenContractNoSwapCarScenario,
        openContractNoSwapCarScenario,
    };
    return scenario;
};
