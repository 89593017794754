var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NATIVE_EVENTS } from '@constants/constants';
import React from 'react';
const CopyButton = ({ children, text }) => {
    const handleCopy = () => __awaiter(void 0, void 0, void 0, function* () {
        // Standard Web Copy
        navigator.clipboard.writeText(text);
        // Native WebView Copy
        yield window.JSBridge.send(NATIVE_EVENTS.copyToClipboard, text);
        yield window.JSBridge.send(NATIVE_EVENTS.showToast, 'Copied to clipboard');
    });
    if (children) {
        return (React.createElement("a", { onClick: handleCopy, style: {
                display: 'flex',
                gap: 5,
            } },
            children,
            React.createElement("div", { style: {
                    width: 20,
                    height: 20,
                    display: 'inline-grid',
                    placeItems: 'center',
                } },
                React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { d: "M9.99935 1.66699C8.1584 1.66699 6.66602 3.15938 6.66602 5.00033H8.33268C8.33268 4.07985 9.07885 3.33366 9.99935 3.33366H14.9993C15.9198 3.33366 16.666 4.07985 16.666 5.00033V10.0003C16.666 10.9208 15.9198 11.667 14.9993 11.667V13.3337C16.8403 13.3337 18.3327 11.8412 18.3327 10.0003V5.00033C18.3327 3.15938 16.8403 1.66699 14.9993 1.66699H9.99935Z", fill: "#136FD8" }),
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3327 15.0003C13.3327 16.8412 11.8403 18.3337 9.99935 18.3337H4.99935C3.1584 18.3337 1.66602 16.8412 1.66602 15.0003V10.0003C1.66602 8.15938 3.1584 6.66699 4.99935 6.66699H9.99935C11.8403 6.66699 13.3327 8.15938 13.3327 10.0003V15.0003ZM3.33268 10.0003C3.33268 9.07983 4.07887 8.33366 4.99935 8.33366H9.99935C10.9198 8.33366 11.666 9.07983 11.666 10.0003V15.0003C11.666 15.9208 10.9198 16.667 9.99935 16.667H4.99935C4.07887 16.667 3.33268 15.9208 3.33268 15.0003V10.0003Z", fill: "#136FD8" })))));
    }
    return (React.createElement("a", { onClick: handleCopy, style: {
            width: 20,
            height: 20,
            display: 'inline-grid',
            placeItems: 'center',
        } },
        React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M9.99935 1.66699C8.1584 1.66699 6.66602 3.15938 6.66602 5.00033H8.33268C8.33268 4.07985 9.07885 3.33366 9.99935 3.33366H14.9993C15.9198 3.33366 16.666 4.07985 16.666 5.00033V10.0003C16.666 10.9208 15.9198 11.667 14.9993 11.667V13.3337C16.8403 13.3337 18.3327 11.8412 18.3327 10.0003V5.00033C18.3327 3.15938 16.8403 1.66699 14.9993 1.66699H9.99935Z", fill: "#136FD8" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3327 15.0003C13.3327 16.8412 11.8403 18.3337 9.99935 18.3337H4.99935C3.1584 18.3337 1.66602 16.8412 1.66602 15.0003V10.0003C1.66602 8.15938 3.1584 6.66699 4.99935 6.66699H9.99935C11.8403 6.66699 13.3327 8.15938 13.3327 10.0003V15.0003ZM3.33268 10.0003C3.33268 9.07983 4.07887 8.33366 4.99935 8.33366H9.99935C10.9198 8.33366 11.666 9.07983 11.666 10.0003V15.0003C11.666 15.9208 10.9198 16.667 9.99935 16.667H4.99935C4.07887 16.667 3.33268 15.9208 3.33268 15.0003V10.0003Z", fill: "#136FD8" }))));
};
export default CopyButton;
