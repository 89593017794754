var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BE_SERVICE_API, NATIVE_EVENTS } from '@constants/constants';
let appsAttribute;
export const initializeAppsAttribute = () => __awaiter(void 0, void 0, void 0, function* () {
    const jsonAppsAttribute = yield window.JSBridge.send(NATIVE_EVENTS.token);
    if (jsonAppsAttribute) {
        appsAttribute = JSON.parse(jsonAppsAttribute);
        return;
    }
    appsAttribute = {
        token: '',
        countryCode: '',
        publicId: '',
        currentLocation: '',
    };
    if (process.env.NODE_ENV === 'development') {
        if (localStorage.getItem('__car_sharing_role__') == 'main') {
            appsAttribute = {
                "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6Il9kZWZhdWx0IiwidHlwIjoiSldUIn0.eyJhdWQiOiJEUklWRVIiLCJlc2kiOiJGNTJUUlVjQmNMZ1NvVkh6SDlUVGFVd3B4VFBHUElXZ2RXdHV2UGx4UWpCN1AwS3YiLCJleHAiOjQ4ODM2ODMzMDgsImlhdCI6MTczMDA4MzMwNSwianRpIjoiNWM5ZDc2OTQtODQ5MS00MzE2LTkzMjUtOWRjMWE5YjA1NzQ2IiwibG1lIjoiU1lTVEVNIiwibmFtZSI6IiIsInN1YiI6IjE4Nzg2YmM5LTdjODctNGJiMS1iMGZkLTlkMWVjOWEyZmY0YiJ9.pOR-3e8YFiv1M_tolEtnG1lbWQMOc91RT71mbP45jCqjHEhruxAAl8pIrcCYg_36KyGQEKWiP00IETLwWM7_ah5eXVVQzYUWgSKfE3UV6LAAQQmJ9JDwJpyc8JaeTQtkQoY-rVz28pKBQQqTgJ25ircv378MHKN7Z8cRm96u_HJ16QjfVvO_O0AsY98M2zsP0hKY66UG4279DKN2VpNZWxMuqtC45bsFkIQ1DBt7ZtcYsT_sSqk8q4Ptd31zRJm4XT-QywEklqrZoMR4Gr5owGec2NNTh_QXyTTfynGt_dfLI64BsNXVhVBA_zo3UEE8Ti3oOULlqDK9YrQz70EzIBgoA1mX6_kb5ot02fbywGjq6Z5e3NL4yhoOfs0_lESgydCKcqe-swaOguWgSfHeNf9zBbxQFGrqAtFIj7WMg60Ibj1h0YZw2hZcqnd_4RZ9hAg1AUDNT7Db8kMJP-7L92-9TIwXIQ57ubwseIw8S8BI-fucOY0MuAt47LrOkxcFyCOj7ZfwzO2zY-8cycLoXaLVuTEx3JcYBzyCSleC-fQh2Qe_YhICyQggni-l_RThdLb_MwtjTSHY3hd1XMYncdjHYMDT6D16OnsT38uPwClz3liP-vrWmNg42J94_6juwdAeUpVOrXJXoteCH24XwDRYZGKj6gGXMDeZHimPU7g",
                "publicId": "b142b4c6-5783-11ee-8000-000000000000",
                "countryCode": "sg",
                "currentLocation": "1.326666,103.933302",
            };
        }
        else if (localStorage.getItem('__car_sharing_role__') == 'relief') {
            appsAttribute = {
                "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6Il9kZWZhdWx0IiwidHlwIjoiSldUIn0.eyJhdWQiOiJEUklWRVIiLCJlc2kiOiJGNTJUUlVjQmNMZ1NvVkh6SDlUVGFVd3B4VFBHUElXZ2RXdHV2UGx4UWpCN1AwS3YiLCJleHAiOjQ4ODM2ODMzMDgsImlhdCI6MTczMDA4MzMwNSwianRpIjoiNWM5ZDc2OTQtODQ5MS00MzE2LTkzMjUtOWRjMWE5YjA1NzQ2IiwibG1lIjoiU1lTVEVNIiwibmFtZSI6IiIsInN1YiI6IjE4Nzg2YmM5LTdjODctNGJiMS1iMGZkLTlkMWVjOWEyZmY0YiJ9.pOR-3e8YFiv1M_tolEtnG1lbWQMOc91RT71mbP45jCqjHEhruxAAl8pIrcCYg_36KyGQEKWiP00IETLwWM7_ah5eXVVQzYUWgSKfE3UV6LAAQQmJ9JDwJpyc8JaeTQtkQoY-rVz28pKBQQqTgJ25ircv378MHKN7Z8cRm96u_HJ16QjfVvO_O0AsY98M2zsP0hKY66UG4279DKN2VpNZWxMuqtC45bsFkIQ1DBt7ZtcYsT_sSqk8q4Ptd31zRJm4XT-QywEklqrZoMR4Gr5owGec2NNTh_QXyTTfynGt_dfLI64BsNXVhVBA_zo3UEE8Ti3oOULlqDK9YrQz70EzIBgoA1mX6_kb5ot02fbywGjq6Z5e3NL4yhoOfs0_lESgydCKcqe-swaOguWgSfHeNf9zBbxQFGrqAtFIj7WMg60Ibj1h0YZw2hZcqnd_4RZ9hAg1AUDNT7Db8kMJP-7L92-9TIwXIQ57ubwseIw8S8BI-fucOY0MuAt47LrOkxcFyCOj7ZfwzO2zY-8cycLoXaLVuTEx3JcYBzyCSleC-fQh2Qe_YhICyQggni-l_RThdLb_MwtjTSHY3hd1XMYncdjHYMDT6D16OnsT38uPwClz3liP-vrWmNg42J94_6juwdAeUpVOrXJXoteCH24XwDRYZGKj6gGXMDeZHimPU7g",
                "publicId": "b142b4c6-5783-11ee-8000-000000000000",
                "countryCode": "sg",
                "currentLocation": "1.326666,103.933302",
            };
        }
    }
});
export const setAppsAttribute = (attr) => {
    appsAttribute = attr;
};
export const getCountryCode = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.countryCode ? appsAttribute.countryCode : 'sg';
});
export const getPublicId = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.publicId ? appsAttribute.publicId : '';
});
export const getCurrentLocation = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!appsAttribute) {
        yield initializeAppsAttribute();
    }
    return appsAttribute.currentLocation ? appsAttribute.currentLocation : '';
});
export const configWithAuthorization = (beService = BE_SERVICE_API.jaya, config = {}) => {
    switch (beService) {
        case BE_SERVICE_API.jaya:
            config.headers = Object.assign(Object.assign({}, config.headers), { Authorization: appsAttribute.token });
            break;
        case BE_SERVICE_API.geoPoi:
            config.headers = Object.assign(Object.assign({}, config.headers), { 'X-MTS-SSID': appsAttribute.token });
            break;
    }
    return config;
};
