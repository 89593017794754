var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
const Flex = (_a) => {
    var { children, direction = 'row', justify, align, gap = 0, style = {}, fullWidth = true } = _a, restProps = __rest(_a, ["children", "direction", "justify", "align", "gap", "style", "fullWidth"]);
    const styles = Object.assign(Object.assign({}, style), { display: 'flex', flexDirection: direction, justifyContent: justify, alignItems: align, gap: `${gap}px`, width: fullWidth ? '100%' : 'auto' });
    return (React.createElement("div", Object.assign({ style: styles }, restProps), children));
};
export default Flex;
