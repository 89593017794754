import { ENVIRONMENT } from '@constants/constants';
import { getEnvironment } from '@helpers';
import React, { createContext, useContext } from 'react';
import VConsole from 'vconsole';
let vConsole;
const VConsoleContext = createContext({
    showVConsoleSwitch: () => { },
});
export const useVConsole = () => useContext(VConsoleContext);
export const VConsoleProvider = ({ children }) => {
    const showVConsoleSwitch = () => {
        vConsole = new VConsole();
        vConsole.showSwitch();
    };
    if (getEnvironment() === ENVIRONMENT.PRD) {
        return children;
    }
    return (React.createElement(VConsoleContext.Provider, { value: { showVConsoleSwitch } }, children));
};
