import React, { useState } from 'react';
import { NavBar } from 'antd-mobile';
import styles from './HeaderNavbar.less';
import { useNavigate } from 'react-router';
import { useVConsole } from '@hooks/useVConsole';
import useScrollPosition from '@hooks/useScrollPosition';
import clsx from 'clsx';
const HeaderNavbar = ({ v2 = false, padding = '16px', right, className, navbarText, navbarCenter, onBack, customBackArrow, height = 80, dark = false, dynamicBackground = false, dynamicBackgroundYOffset = 0, }) => {
    const { showVConsoleSwitch } = useVConsole();
    const scrollPosition = useScrollPosition();
    const [developerMode, setDeveloperMode] = useState(false);
    const [developerClickCount, setDeveloperClickCount] = useState(0);
    const handleDeveloperMode = () => {
        if (developerMode)
            return;
        setDeveloperClickCount(prev => prev + 1);
        if (developerClickCount === 10) {
            setDeveloperMode(true);
            showVConsoleSwitch();
        }
    };
    const navigate = useNavigate();
    const defaultBack = () => {
        navigate(-1);
    };
    return (React.createElement("div", { className: className },
        v2 ? (React.createElement("div", { className: clsx(dark ? styles.darkNavbar : styles.lightNavbar), style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: padding,
                height: height,
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                } },
                React.createElement("div", { onClick: onBack || defaultBack }, customBackArrow || (React.createElement("svg", { style: { marginTop: '6px' }, xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
                    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.9732 11.0165H7.50393L12.5147 6.19275C12.9151 5.80725 12.9151 5.17463 12.5147 4.78913C12.1142 4.40362 11.4674 4.40362 11.0669 4.78913L4.30034 11.3031C3.89989 11.6886 3.89989 12.3114 4.30034 12.6969L11.0669 19.2109C11.4674 19.5964 12.1142 19.5964 12.5147 19.2109C12.9151 18.8254 12.9151 18.2026 12.5147 17.8171L7.50393 12.9934H18.9732C19.5379 12.9934 20 12.5486 20 12.0049C20 11.4613 19.5379 11.0165 18.9732 11.0165Z", fill: dark ? '#FFFFFF' : '#1A1A1A' })))),
                typeof navbarCenter !== 'string' && !navbarText ? (navbarCenter) : (React.createElement("h2", { onClick: handleDeveloperMode, style: { margin: 0, color: dark ? '#FFFFFF' : '#1A1A1A' } }, navbarText || navbarCenter))),
            React.createElement("div", null, right))) : (React.createElement(NavBar, { right: right, style: { background: '#ffffff' }, backArrow: customBackArrow || (React.createElement("svg", { className: styles.backArrow, xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.9732 11.0165H7.50393L12.5147 6.19275C12.9151 5.80725 12.9151 5.17463 12.5147 4.78913C12.1142 4.40362 11.4674 4.40362 11.0669 4.78913L4.30034 11.3031C3.89989 11.6886 3.89989 12.3114 4.30034 12.6969L11.0669 19.2109C11.4674 19.5964 12.1142 19.5964 12.5147 19.2109C12.9151 18.8254 12.9151 18.2026 12.5147 17.8171L7.50393 12.9934H18.9732C19.5379 12.9934 20 12.5486 20 12.0049C20 11.4613 19.5379 11.0165 18.9732 11.0165Z", fill: "#1A1A1A" }))), className: styles.navbar, onBack: onBack || defaultBack }, React.createElement("div", { onClick: handleDeveloperMode }, navbarText))),
        React.createElement("div", { className: v2 ? styles.navPlaceholderV2 : styles.navPlaceholder, style: { backgroundColor: dark ? '#184440' : '#FFFFFF' } })));
};
export default HeaderNavbar;
