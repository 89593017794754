import { hot } from 'react-hot-loader/root';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ScribeProvider } from '@hooks/useScribe';
import { Spinner } from '@components';
import styles from './App.less';
import { VConsoleProvider } from '@hooks/useVConsole';
import Toasty from '@components/toasty';
const renderRoutes = (routes) => {
    let rs = routes.map(route => {
        return (React.createElement(Route, { key: route.pathname, path: route.pathname, element: route.element }));
    });
    if (process.env.NODE_ENV === 'development') {
        rs.push(React.createElement(Route, { key: 'dev', path: 'main', element: React.createElement(DevURLRederict, null) }));
        rs.push(React.createElement(Route, { key: 'dev', path: 'relief', element: React.createElement(DevURLRederict, null) }));
    }
    return rs;
};
const DevURLRederict = () => {
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            if (window.location.pathname == '/main') {
                localStorage.setItem('__car_sharing_role__', 'main');
                window.location.replace('/landing-page-v2?role=vehicle_provider');
            }
            else if (window.location.pathname == '/relief') {
                localStorage.setItem('__car_sharing_role__', 'relief');
                window.location.replace('/landing-page-v2?role=driver_candidate');
            }
        }
    }, []);
    return React.createElement(React.Fragment, null);
};
const App = () => (React.createElement("div", { className: styles.layout },
    React.createElement(Toasty, null),
    React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(ScribeProvider, null,
            React.createElement(VConsoleProvider, null,
                React.createElement(Routes, null, renderRoutes(ROUTES)))))));
App.displayName = 'App';
export default hot(App);
