export const NATIVE_EVENTS = {
    token: 'GetToken',
    close: 'CloseDetail',
    countryCode: 'GetCountryCode',
    openHelpCenter: 'OpenHelpCenter',
    showToast: 'ShowToast',
    driverId: 'GetDriverId',
    currentLocation: 'GetCurrentLocation',
    copyToClipboard: 'CopyToClipboard',
};
export const BE_SERVICE_API = {
    jaya: 'Jaya',
    geoPoi: 'GeoPoi',
};
export const COUNTRIES_TO_CITY_ID = {
    Singapore: 6,
};
export const RENTAL_PREFERENCES = {
    RENT_ALONE: 'Rent Alone',
    SHARING: 'Sharing',
};
export const ASSETS_MAPPING = {
    PRD: {
        WALLET: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/wallet.png',
        TIME: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/time.png',
        SHARING: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/sharing.png',
        RENT_ALONE: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/rent_alone.png',
    },
    STG: {
        WALLET: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/wallet.png',
        TIME: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/time.png',
        SHARING: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/sharing.png',
        RENT_ALONE: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/rent_alone.png',
    },
    DEV: {
        WALLET: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/wallet.png',
        TIME: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/time.png',
        SHARING: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/sharing.png',
        RENT_ALONE: 'https://daxexp.stg-myteksi.com/jaya-dax-app/assets/rent_alone.png',
    },
};
export const SIGNUP_STATUS = {
    PENDING_DOC: 'pending_doc',
    SUBMITTED: 'submitted',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    REQUIRE_UPDATE: 'require_update',
};
export const DOCUMENT_TYPE = {
    FRONT_IDENTITY_CARD: 'identity_card_F',
    FRONT_DRIVING_LICENSE: 'driving_license_F',
    FRONT_VOCATIONAL_LICENSE: 'vocational_license_F',
    BACK_IDENTITY_CARD: 'identity_card_B',
    BACK_DRIVING_LICENSE: 'driving_license_B',
    BACK_VOCATIONAL_LICENSE: 'vocational_license_B',
};
export const ADD_ONS_TYPE = {
    COLLISION_DAMAGE_WAIVER: 'collision_damage_waiver',
    VEHICLE_DAMAGE_PROTECTION: 'vehicle_damage_protection',
};
export const RATES = {
    '11:00 AM - 10:59 PM': 34.72,
    '11:00 PM - 10:59 AM': 26.39,
    '4:00 AM - 3:59 PM': 33.33,
    '4:00 PM - 3:59 AM': 27.78,
    [ADD_ONS_TYPE.COLLISION_DAMAGE_WAIVER]: 2.5,
    [ADD_ONS_TYPE.VEHICLE_DAMAGE_PROTECTION]: 1.39,
};
export const PAGES = {
    LANDING_PAGE: 'landing-page',
    RENTAL_PREFERENCE: 'rental-preference',
    INTERESTED: 'interested',
    UPLOAD_DOCUMENT: 'upload-document',
    DOCUMENT_CHECK: 'document-check',
    SHARING_PREFERENCE: 'sharing-preference',
    SHARING_PREFERENCE_V2: 'sharing-preference-v2',
    SHARING_CAR_MODEL_PREFERENCE: 'sharing-car-model-preference',
    SEARCH_DRIVERS_RESULT: 'search-drivers-result',
    SEARCH_DRIVERS_RESULT_V2: 'search-drivers-result-v2',
    PROPOSAL_INVITATION: 'proposal-invitation',
    PROPOSAL_INVITATION_V2: 'proposal-invitation-v2',
    PROPOSAL_PROGRESS: 'proposal-progress',
    PROPOSAL_PROGRESS_V2: 'proposal-progress-v2',
    RENTAL_SUMMARY: 'rental-summary',
    RENTAL_SUMMARY_V2: 'rental-summary-v2',
    EDIT_SHARING_PREFERENCE: 'edit-sharing-preference',
    CAR_SHARING_V2: 'car-sharing-v2',
};
export const PROPOSAL_STATUSES = {
    PROPOSED: 'proposed',
    ACCEPTED: 'accepted',
    SHARING: 'sharing',
    REJECTED: 'rejected',
    CANCELLED: 'cancelled',
    TERMINATED: 'terminated',
};
export const PROPOSAL_ROLES = {
    SENDER: 'sender',
    RECEIVER: 'receiver',
};
export const CAR_SHARING_V2_ROLES = {
    VEHICLE_PROVIDER: 'vehicle_provider',
    DRIVER_CANDIDATE: 'driver_candidate',
};
export const GST_PERCENTAGE = 8;
export const GST_PERCENTAGE_AFTER_CHANGE = 9;
export const AGREEMENT_STATUSES = {
    OPEN: 'open',
    OPEN_CONTRACT: 'open_contract',
};
// export const ERROR_NO_PROPOSAL = 'cannot find proposal publicID';
export const ERROR_NO_PROPOSAL = {
    FIRST: 'cannot find proposal publicID',
    SECOND: 'no active proposal',
};
export const ENVIRONMENT = {
    DEV: 'dev',
    STG: 'stg',
    PRD: 'prd',
};
export const SHARING_PERIOD_TERMS = {
    LONG_TERM: 'long_term',
    SHORT_TERM: 'short_term',
};
export const CAR_SHARING_V2_LINKS = {
    RENTAL_FEATURE: 'http://grb.to/driverapp-rentalfeature',
    HELP_CENTER: 'https://help.grab.com/driver/en-sg/24643362349465',
    REMOVE_DRIVER: 'https://help.grab.com/driver/en-sg/33439807128089',
    ADD_ADDITIONAL_DRIVER: 'https://help.grab.com/driver/en-sg/360032855051-request-to-add-an-authorised-additional-grabrentals-driver',
    PRIVACY_NOTICE: 'https://www.grab.com/sg/terms-policies/privacy-notice',
};
