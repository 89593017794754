import axios from 'axios';
import { buildResponse } from './utils';
import { configWithAuthorization } from './nativeService';
import { BE_SERVICE_API } from '@constants/constants';
export const post = (uri, config = {}, beService = BE_SERVICE_API.jaya) => {
    config = configWithAuthorization(beService, config);
    const axiosConfig = {
        string: '',
        headers: config.headers,
    };
    return axios
        .post(uri, config.data, axiosConfig)
        .then(res => buildResponse(res.status, res.data, null))
        .catch(err => {
        console.log(`[Error] uri: ${uri} message:${err.message} ${err.response
            ? `response_data: ${JSON.stringify(err.response.data)}`
            : ''}`);
        return buildResponse(err.response ? err.response.status : 0, {}, err.response.data);
    });
};
