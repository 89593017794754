import React from 'react';
import { Grid } from 'antd-mobile';
import styles from './PreferencesBox.less';
const PreferencesBox = ({ parkingAddress, timeSlot, onClick, }) => {
    return (React.createElement("div", { onClick: onClick, style: { paddingTop: '5px', position: 'relative' } },
        React.createElement(Grid, { className: styles.box, columns: 1 },
            React.createElement(Grid.Item, { span: 1 },
                React.createElement("span", { className: styles.address }, parkingAddress)),
            React.createElement(Grid.Item, { span: 1 },
                React.createElement("span", { className: styles.timeSlot }, timeSlot)))));
};
export default PreferencesBox;
